window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
window.toastr = require('toastr/toastr');
window.swal = require('sweetalert');

require('bootstrap')
require('slick-carousel');
require('waypoints/src/waypoint');
require('waypoints/src/shortcuts/sticky');
require('waypoints/src/adapters/jquery');
require('waypoints/src/group');
require('waypoints/src/context');

require('../vendor/js/template')
require('../vendor/js/custom')



/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
